:root {
  --swiper-navigation-size: 0.8rem;
  --swiper-navigation-color: var(--primary-color);
  --swiper-navigation-width: 3px;
}
#new-portfolio {
  background-color: var(--bg-green);
  padding-bottom: 2rem;
  padding: 2rem 5%;
}
#new-portfolio h1 {
  color: var(--primary-color);
  padding: 2rem 0px;
  /* font-family: "El Messiri", sans-serif; */
}
.my-swiper {
  margin-bottom: 2rem;
}
