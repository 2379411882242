.blog-pg {
  background-color: var(--bg-dark);
  padding: 4rem 0 2rem 0;
}

.blog-img-ctn {
  width: 100%;
  height: 20rem;
  object-fit: cover;
  margin-bottom: 2rem;
}
.title-ctn {
  width: 60%;
  margin: 0 auto;
}
.blog-title {
  padding: 1rem 0;
}
.blog-content {
  width: 60%;
  margin: 0 auto;
  letter-spacing: 1px;
}
/* .blog-content h3 {
  margin: 1rem 0;
}
.blog-content p {
  margin-bottom: 1rem;
} */
.blog-h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.blog-p {
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 1rem;
}
.blog-big-img {
  width: 100%;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 800px) {
  .blog-content {
    width: 90%;
    letter-spacing: 2px;
  }
}
