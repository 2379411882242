.navbar {
  position: fixed;
  top: 0;
  min-width: 90%;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 5% 20px 5%;
  background-color: var(--bg-green);
}
.menu-items {
  display: flex;
  flex-direction: row;
}
.menu {
  margin-left: 20px;
  font-weight: 600;
  cursor: pointer;
}
.logo {
  cursor: pointer;
  height: 2rem;
}
