body {
  background-color: var(--bg-green);
}
.blogs {
  padding-top: 3rem;
  background-color: var(--bg-dark);
  padding-bottom: 2rem;
  text-align: center;
}
.blogs h1 {
  color: var(--primary-color);
  padding: 1rem 0px;
  /* font-family: "El Messiri", sans-serif; */
}
.blogs-header {
  height: 20rem;
  width: 100%;
}
.img-header {
  height: 15rem;
  width: 100%;
  object-fit: cover;
}
.featured-blog {
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: start;
}
.feat-img {
  height: 15rem;
  width: 35%;
  object-fit: cover;
  margin-right: 3rem;
}
.feat-desc {
  width: 35%;
}
.feat-desc p {
  vertical-align: middle;
}
.feat-desc h1 {
  vertical-align: middle;
  margin-bottom: 1rem;
}
.blog-grid {
  padding: 4rem;
}
@media only screen and (max-width: 1200px) {
  .featured-blog {
    padding: 0;
    flex-direction: column;
    text-align: center;
  }
  .feat-img {
    height: 15rem;
    width: 90%;
    object-fit: cover;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  .feat-desc {
    width: 90%;
    margin: 0 auto;
  }
  .blog-grid {
    padding: 1rem;
  }
}
