@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Outfit:wght@100;200;300;400;500;600;700;900&display=swap");

:root {
  --bg-green: #222222;
  --bg-lightgray: #292929;
  --bg-darkgray: #171717;
  --bg-dark: #001724;
  --bg-gray: #f5f5f5;
  --primary-color: #fd8a5c;
  overflow-x: hidden;
}
* {
  font-family: "Outfit", sans-serif;
  color: white;
  margin: 0;
  /* overflow-x: hidden; */
}
p {
  font-weight: 300;
}
.primary-one-txt {
  color: var(--primary-color);
  font-size: 2rem;
  margin-bottom: 10px;
  font-weight: 500;
}
.primary-two-txt {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin: 40px 0;
  font-weight: 500;
}
.primary-three-txt {
  color: var(--primary-color);
  font-size: 1rem;
  margin-bottom: 10px;
  font-weight: 500;
}
.primary-four-txt {
  color: var(--primary-color);
  font-size: 0.7rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
}
