/* .landing-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10% 5%;
  background-color: var(--bg-green);
  position: relative;
  min-height: 60vh;
  overflow: hidden;
} */
.profile-img {
  height: 60vh;
}
.app-img {
  position: absolute;
  height: 15rem;
  bottom: 40%;
  left: 35%;
}
.name-title {
  color: var(--primary-color);
  font-size: 4em;
  font-weight: 700;
  text-align: left;
  font-family: "Outfit", sans-serif;
}
.divider {
  background-color: white;
  height: 3px;
  width: 100px;
  overflow: hidden;
  margin: 40px 0px;
}
.intro-content {
  text-align: left;
  max-width: 15%;
}
.intro-desc {
  font-size: 1.3rem;
}
.profile {
  display: flex;
  flex-direction: column;
  justify-content: left;
}
.contact-icon {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-end;
}
.hvr-icon:hover {
  transition: top ease 0.5s;
}
@media only screen and (max-width: 850px) {
  .profile-bg {
    display: none;
  }
  .intro-content {
    text-align: center;
    max-width: 30%;
    text-align: start;
  }
}
@media only screen and (max-width: 1000px) {
  .profile-img {
    display: none;
  }

  .profile {
    margin-top: 2rem;
  }
  .landing-page {
    flex-direction: column;
    align-items: center;
  }
  .intro-content {
    text-align: center;
    max-width: 70%;
  }
  .contact-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 2rem;
  }
  .name-title {
    text-align: center;
  }
  .divider {
    margin: 40px auto;
  }
}
@media only screen and (max-width: 1200px) {
  .profile-img {
    height: 50vh;
  }
}
