.service {
  min-width: 350px;
  width: 30%;
  height: 30rem;
  padding: 2rem 0px;
}
.service h2 {
  margin-bottom: 4rem;
  /* color: var(--bg-dark); */
}
.desc {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  padding: 0px 2rem;
}
.service p {
  margin-left: 2rem;
  /* color: var(--bg-dark); */
}
@media only screen and (max-width: 350px) {
  .service {
    min-width: 90%;
    width: 90%;
    padding: 2rem 0px;
  }
}
