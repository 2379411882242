.App {
  position: relative;
  /* overflow: hidden; */
  text-align: center;
  background-color: var(--bg-green);
  overflow-x: hidden;
}
.email {
  width: 2rem;
  transform: rotate(-90deg);
  letter-spacing: 0.45rem;
  font-size: 0.7rem;
}
.line {
  position: absolute;
  z-index: 0.6;
  background-color: white;
  width: 0.05rem;
  height: 15rem;
  bottom: 0;
  margin: 0 1rem;
}
.email-line {
  text-align: left;
  position: fixed;
  left: 0.5rem;
  bottom: 0;
  z-index: 0.6;
  width: 2rem;
  height: 20rem;
}
