#new-about-me {
  background-color: var(--bg-green);
  padding: 1rem 5%;
}
#new-about-me h1 {
  color: var(--primary-color);
  /* font-family: "El Messiri", sans-serif; */
}
#new-about-me-container {
  padding: 1rem 0px;
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: 25%;
}
.language {
  text-align: left;
  width: 12rem;
}
.language p {
  margin-bottom: 1rem;
}
.exp-container {
  padding: 0rem 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.exp-txt h1 {
  color: var(--primary-color);
}
.my-exp-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50vw;
  /* align-items: center; */
  padding-left: 25%;
  margin-top: 2rem;
  text-align: start;
}
.exp-progress {
  display: flex;
  flex-direction: row;
  height: 4rem;
  margin-top: 0.1rem;
}
.exp-progress-sel {
  display: flex;
  flex-direction: row;
  height: 4rem;
  margin-top: 0.1rem;
  border-radius: 0.3rem;
  background-color: #00283f;
}
.prgrss-wdgt {
  margin-right: 1rem;
}
.prgrss-circle {
  background-color: var(--primary-color);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-bottom: 0.4rem;
}
.prgrss-grey-circle {
  background-color: lightgray;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-bottom: 0.4rem;
}
.prgrss-line {
  width: 0.1rem;
  height: 2rem;
  background-color: white;
  margin-bottom: 0.4rem;
  margin-left: 0.4rem;
}
.exp-cmp-name {
  cursor: pointer;
  color: var(--primary-color);
}
.exp-cmp-grey-name {
  cursor: pointer;
  color: white;
}
.role-list {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}
.exp-ctnt-container {
  margin-top: 1rem;
}
.ux-skills {
  padding: 0;
}
.dev-skills {
  padding: 0;
  /* margin-right: 1rem; */
}
.hi-there {
  margin-top: 2rem;
  text-align: start;
}
.skills-container {
  background-color: beige;
  position: relative;
  width: 100%;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: transparent;
  border-radius: 100%;
  width: 2.4rem;
  height: 2.4rem;
  padding: 12px;
  border: 1px solid var(--bg-lightgray);
}
@media only screen and (max-width: 1200px) {
  #new-about-me {
    padding: 1rem 5%;
  }
  #new-about-me-container {
    width: 90%;
    padding-left: 5%;
    text-align: center;
  }
  .exp-container {
    padding: 0;
  }
}
@media only screen and (max-width: 800px) {
  .my-exp-container {
    flex-direction: column;
    width: 80vw;
    /* align-items: center; */
    padding-left: 5%;
    margin-top: 2rem;
    text-align: center;
  }
  .exp-ctnt-container {
    margin-top: 2rem;
  }
  .role-list {
    text-align: start;
  }
  .language {
    text-align: center;
  }
}
