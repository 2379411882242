.primary-button {
  position: relative;
  cursor: pointer;
  width: 200px;
  height: 50px;
  margin: 2rem 0rem;
  border: 0.5px solid var(--primary-color);
}
.bg-box {
  height: 100%;
  width: 30%;
  background-color: var(--primary-color);
  opacity: 0.5;
  transition: 0.3s;
}
.btn-name {
  position: absolute;
  left: 20%;
  top: 25%;
  transition: 0.3s;
}
.btn-icon {
  position: absolute;
  right: 10%;
  top: 30%;
  fill: white;
}
.primary-button:hover .bg-box {
  opacity: 1;
  width: 100%;
}
.primary-button:hover .btn-name {
  color: var(--bg-dark);
}
.primary-button:hover .btn-icon {
  color: black;
  fill: var(--bg-dark);
}
